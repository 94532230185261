.list-item {
  margin: 1% 0%;
  .list-item-button {
    min-height: 3.5rem;
    max-height: 3.5rem;
    .list-item-button-text {
      width: 80%;
    }
  }
}
