.global-summary-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .global-summary-grid {
    width: 100%;
    justify-content: center;
    .global-summary-grid-item {
      padding: 1.5%;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        padding: 3%;
      }
      .global-summary-dates-container {
        border: 1px solid #ffc107;
        width: 70%;
        @media screen and (max-width: 768px) {
          width: 90%;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgb(239, 239, 239, 0.7);
        border-radius: 10px;
        padding: 2% 0.5%;
        margin-bottom: 2%;
        @media screen and (max-width: 768px) {
          margin-top: 3%;
          padding: 0 0 8% 0;
        }
      }
      .global-summary-data-container {
        border: 1px solid lightgray;
        border-radius: 8px;
        width: 100%;
        min-height: 22vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        .global-summary-data-title {
          font-size: 1.035rem;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        }
        .global-summary-data {
          font-size: 1.35rem;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        }
      }
    }
  }
}
