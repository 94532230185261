.lh-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .grid-logo-container {
    background: linear-gradient(to bottom, #4e4e4ec9, #ffffff);
    width: 100%;
    margin: 0%;
    display: flex;
    justify-content: center;
    .logo {
      display: flex;
      justify-content: center;
      .newLogo {
        max-width: 270px;
        max-height: 210px;
      }
    }
  }
}
