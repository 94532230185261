.containerLogged {
  background-color: white;
  height: 100%;
}

.unstyled {
  color: black;
  text-decoration: none;
  &:hover {
    color: black;
  }
}

.login-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  justify-content: center;
}

.drawer-content-container {
  width: 100%;
  height: 100%;
  min-height: 85vh;
  display: flex;
  justify-content: center;
  padding: 1%;
  margin-top: 80px;
  > main {
    box-sizing: border-box;
    padding: 1% 0;
    > div {
      display: none;
    }
    .children-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
