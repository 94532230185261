.customTextField-main-container {
  .customTextField-inner-container {
    .customTextField-label {
      color: black;
      text-align: flex-start;
    }
  }
}

.custom-textField {
  label {
    color: black;
  }
}
