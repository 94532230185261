.General {
  text-align: center;
  width: 100%;
  .ownersHome-screen-title {
    text-align: center;
    font-size: 2.05em;
    letter-spacing: 0.03em;
    margin-bottom: 1%;
    text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
  }
}

.General-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .General-logo {
    animation: General-logo-spin infinite 20s linear;
  }
}

.General-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.General-link {
  color: #61dafb;
}

@keyframes General-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// ------button add owner------
.btt1 {
  padding: 1.3em 3em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: rgb(243, 243, 231);
  background-color: rgb(28, 160, 72);
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(28, 160, 72);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.btt1:hover {
  background-color: #23c483;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

.btt1:active {
  transform: translateY(-1px);
}
