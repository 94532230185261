.barbershops-detail-page {
  width: 100%;
  .barbershops-detail-main-container {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .barbershops-detail-table-container {
      height: 100%;
      width: 75%;
      @media screen and (max-width: 1024px) {
        width: 100%;
        margin-bottom: 5%;
      }
      .barbershops-detail-table {
        width: 100%;
        .barbershops-detail-table-header {
          background-color: rgb(255, 227, 143);
        }
        .barbershops-detail-table-title {
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
          font-size: 1.25rem;
        }
      }
      .barbershops-detail-table-body {
        tr:nth-child(odd) {
          background-color: rgba(216, 216, 216, 0.65);
        }
        .barbershops-detail-cell-title {
          width: 50%;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
          font-weight: bold;
          font-size: 1rem;
        }
        .barbershops-detail-cell-data {
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
          font-size: 1.05rem;
        }
      }
    }
  }
  .barbershops-detail-btn-container {
    width: 100%;
    margin-top: 2%;
    display: flex;
    justify-content: center;
  }
  .loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .loader-container {
      border: 2px solid lightgray;
      padding: 0 0 2% 0;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 25%;
      @media screen and (max-width: 1024px) {
        width: 75%;
        padding: 0 0 5% 0;
      }
      .loader-img-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
          width: 100%;
        }
      }
    }
  }
}
