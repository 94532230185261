.barber-add-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    padding: 2% 0;
  }
  .barber-add-title-container {
    width: 50%;
    text-align: center;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  .barber-add-section-container {
    margin-top: 1%;
    width: 90%;
    @media screen and (max-width: 1024px) {
      width: 100%;
      text-align: center;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .barber-add-section-title {
      font-weight: lighter;
      font-size: 1.2rem;
    }
    .barber-add-content-container {
      margin: 2% 0;
      width: 50%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    .barber-add-button-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .barbers-table-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .barbers-table-title-container {
      width: 70%;
      text-align: center;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    .barbers-table-inner-container {
      width: 100%;
      margin-top: 1%;
      height: 10vh;
      @media screen and (max-width: 1024px) {
        height: 25vh;
      }
      .barbers-table {
        .barbers-table-header {
          background-color: #efb300dd;
        }
        .MuiDataGrid-cell {
          justify-content: center;
        }
      }
    }
  }
}
