.barbers-selector-main-container {
  margin: 5px;
}
#menu-
  > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  /* padding: 2% 0%; */
  /* max-height: calc(100% - 127px); */
  align-self: center;
  margin: 0 auto;
  max-height: calc(100% - 10%);
}
