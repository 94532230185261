.valorization-screen {
  width: 100%;
  max-height: 90vh;
  @media screen and (max-width: 1024px) {
    max-height: 93vh;
  }
  .valorization-main-grid {
    margin: 0px;
    padding: 0px;
    height: 100%;
    .valorization-grid-item {
      display: flex;
      flex-direction: column;
      min-height: 80vh;
      @media screen and (max-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .valorization-grid-item-title {
        text-align: center;
      }
    }
  }
}
