.barbers-rating-main-container {
  width: 100%;
  max-height: 100%;
  .barbers-rating-grid {
    width: 100%;
    .barbers-rating-grid-item {
      display: flex;
      justify-content: center;
      align-items: center;
      .barbers-data-card {
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        padding: 5% 1%;
        width: 75%;
        @media screen and (max-width: 1024px) {
          width: 97%;
        }
        .barbers-data-stack {
          /* min-height: 65vh; */
          max-width: 100%;
          .barbers-data-container {
            display: flex;
            justify-content: space-around;
            .data {
              width: 100%;
              text-align: center;
              display: flex;
              justify-content: center;
              flex-direction: column;
              font-family: system-ui, -apple-system, BlinkMacSystemFont,
                "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                "Helvetica Neue", sans-serif;
              .data-title {
                margin-bottom: 3%;
                font-weight: bold;
              }
              .data-value {
                font-size: 1rem;
              }
            }
          }
          .barbers-rating-container {
            height: 15vh;
            .rating {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;
              text-align: center;
              padding: 3% 0;
              .rating-title {
                margin-bottom: 2%;
                font-weight: bold;
                font-family: system-ui, -apple-system, BlinkMacSystemFont,
                  "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                  "Helvetica Neue", sans-serif;
                width: 70%;
              }
              .data-rating {
                display: flex;
                width: 45%;
                justify-content: space-around;
                align-items: center;
                .barber-qualification {
                  color: gray;
                }
              }
              .data-rating-input {
                display: flex;
                width: 60%;
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: 1024px) {
                  width: 75%;
                }
                .barber-qualification-input {
                  color: #ffc107;
                  width: 60%;
                }
                .quali-title-input {
                  width: 40%;
                }
              }
            }
          }
          .barbers-buttons-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }

          .separator {
            margin: 5px;
          }
        }
      }
    }
  }
}
