.range-date-picker-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .range-date-picker-title {
    padding: 3% 2% 2% 1%;
    @media screen and (max-width: 768px) {
      padding: 3%;
    }
  }
  .range-date-picker-main-grid {
    width: 100%;
    display: flex;
    justify-content: center;
    .range-date-picker-form-grid {
      width: 55%;
      @media screen and (max-width: 768px) {
        width: 80%;
      }
      padding: 0px;
      margin: 0px;
      .range-date-picker-grid-item {
        padding: 0px;
        @media screen and (max-width: 768px) {
          margin: 5% 0;
        }
      }
      .range-date-picker-button-container {
        padding: 0px;
        .grid-button {
          width: 100%;
          margin-top: 2%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
