.filtered-rates-main-screen {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .rates-screen-main-title {
    width: 100%;
    text-align: center;
    font-size: 2.05em;
    letter-spacing: 0.03em;
    text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
  }
  .day-shift-data {
    display: flex;
    justify-content: space-around;
    font-size: 1.3rem;
    letter-spacing: 0.025em;
    margin-bottom: 1%;
    .day-shift-title {
      font-weight: bold;
    }
  }
  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 5% 0%;
    .filtered-rates-loader {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .filtered-rates-divider-container {
        width: 100%;
        margin: 5% 0%;
      }
    }
  }
}

.all-button-container {
  margin-top: 3%;
  width: 100%;
  display: flex;
  justify-content: center;
}
