.commission-slider-main-container {
  width: 100%;
  padding: 0% 5%;
  @media screen and (max-width: 1024px) {
    padding: 0% 5%;
  }
  .commission-slider-title {
    margin: 3% 0%;
    text-align: center;
  }
  .commission-slider-error {
    color: red;
  }
  .error-helper-text {
    color: red;
  }
}
