.performance-screen {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .performance-screen-content-container {
    width: 100%;
    @media screen and (max-width: 1024px) {
      padding: 0;
    }
    .performance-screen-grid {
      padding: 0;
      margin: 0;
      width: 100%;
      .performance-grid-item {
        padding: 1%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media screen and (max-width: 1024px) {
          margin-top: 2%;
          padding: 3%;
        }
      }
      .performance-grid-item-monthly {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media screen and (max-width: 1024px) {
          margin-top: 2%;
          padding: 3%;
        }
        .tabs-container {
          padding: 1%;
          width: 100%;
          height: 100%;
          .performance-tabs {
            margin-bottom: 2.25%;
            @media screen and (max-width: 1024px) {
              margin-bottom: 7%;
            }
          }
          .performance-tab-panel {
            > div {
              padding: 0;
            }
            > div > p {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
