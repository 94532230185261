.upload-button-main-container {
  .upload-button-form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    .upload-inner-container {
      display: flex;
      justify-content: stretch;
      width: 100%;
      .upload-data-display {
        width: 100%;
        @media screen and (max-width: 1024px) {
          width: 60% !important;
        }
        .upload-label {
          color: gray;
          font-weight: 500;
        }
        .upload-label-error {
          color: #d50000;
          font-weight: 500;
        }
      }
      .upload-button-container {
        width: 40% !important;
        @media screen and (max-width: 1024px) {
          width: 60% !important;
        }
        .upload-button {
          border: 1px solid #ffc107;
          width: 100%;
          padding: 7% 0;
          color: black;
          .normal-icon {
            color: gray;
          }
          .error-icon {
            color: #d50000;
          }
        }
      }
    }
  }
}
