.error-main-container {
  width: 90%;
  border: 2px solid yellow;
  margin: 0 auto;
  text-align: center;
}
.main-title {
  font-size: 5vw;
  font-weight: bold;
  letter-spacing: 1.2;
}
