.data-grid-container {
  /* border: 1px solid cyan; */
  height: 67vh;
  max-height: 67vh;
  width: 100%;
  @media screen and (max-width: 1024px) {
    margin-top: 5%;
  }
  .data-table {
    .column-title {
      background-color: rgba(240, 180, 0, 0.567);
      text-align: center;
      padding: 0%;
    }
    .MuiDataGrid-row:hover {
      background-color: lightgray;
    }
    .MuiDataGrid-cell {
      justify-content: center;
    }
  }
}

.custom-toolbar-container {
  width: 100%;
  padding: 1% 0;
  @media screen and (max-width: 1024px) {
    height: 10vh;
  }
  .grid-toolbar-container {
    height: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 1024px) {
      justify-content: space-between;
    }
    .grid-toolbar-btn-container {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      @media screen and (max-width: 1024px) {
        justify-content: flex-start;
        padding: 0 5px;
      }
      .create-btn {
        display: flex;
        align-items: center;
        padding: 1.5% 3.5%;
        border: 1px solid lightgray;
        @media screen and (max-width: 1024px) {
          padding: 5%;
        }
        .create-btn-text {
          color: black;
          text-transform: none;
        }
        .create-btn-icon {
          color: black;
          padding: 0px;
          margin: 0px;
        }
        &:hover {
          background: linear-gradient(-45deg, #ffffcc 0%, #ccffcc 100%);
          border: 1px solid black;
        }
      }
    }
    .grid-toolbar-searchbar-container {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      @media screen and (max-width: 1024px) {
        justify-content: center;
        padding: 0 5px;
      }
      .search-bar {
        width: 90%;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }
    }
  }
}
