.bills-dashboard-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .bills-dashboard-screen-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5%;
  }
  .bills-dashboard-screen-title {
    text-align: center;
    padding-bottom: 1%;
    font-size: 2.05em;
    letter-spacing: 0.03em;
    text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
  }
  .bills-dashboard-form-container {
    border: 1px solid #ffc107;
    width: 85%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgb(239, 239, 239, 0.7);
    border-radius: 20px;
    padding: 2% 0.5%;
    margin-bottom: 2%;
  }
  .bills-dashboard-grid-container {
    @media screen and (max-width: 1024px) {
      margin-top: 3%;
    }
    margin: 0px;
    width: 100%;
    .bills-dashboard-grid-item {
      padding: 1%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      .bills-dashboard-data-container {
        padding: 0px;
        width: 100%;
        background-color: rgba(224, 224, 224, 0.7);
      }
      .debtors-grid-container {
        margin: 0;
        padding: 0;
        .debtor-item-container {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1%;
          margin: 0;
          @media screen and (max-width: 1024px) {
            margin-top: 2%;
          }
          .debtor-item {
            display: flex;
            justify-content: center;
            flex-direction: column;
            border: 1px solid #ffc107;
            align-items: center;
            width: 100%;
            padding: 5%;
            .debtor-item-avatar-container {
              width: 100%;
              display: flex;
              justify-content: center;
              .debtor-item-avatar {
                display: flex;
                justify-content: center;
              }
            }
            .debtor-item-data-container {
              width: 100%;
              padding: 1% 0;
              .debtor-item-name {
                font-size: 1.05rem;
              }
              .debtor-item-data {
                color: gray;
              }
            }
          }
        }
      }
    }
  }
}
