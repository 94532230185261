.modify-qr-screen {
  width: 100%;
  .modify-qr-grid {
    height: 100%;
    margin: 0px;
    padding: 0px;
    .modify-qr-grid-item {
      padding: 10px;
      display: flex;
      flex-direction: column;
      .modify-qr-item-title {
        width: 100%;
        text-align: center;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
          Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
          sans-serif;
      }
      .modify-qr-dz-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1024px) {
          width: 100%;
          margin-top: 3%;
        }
        .dropzone {
          cursor: pointer;
          width: 100%;
          border: 2px dashed #999;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
          text-align: center;
          background-color: #e1e1e1;
          border-radius: 8px;
          @media screen and (max-width: 1024px) {
            width: 100%;
          }
          .dz-remove {
            border: 2px solid #920035;
            max-width: 7.5rem;
            padding: 5%;
            margin-top: 1rem;
            text-decoration: none;
            border-radius: 5px;
            color: #920035;
            &:hover {
              text-decoration: none;
              transform: scale(1.015);
            }
          }
          .dropzone .dz-preview.dz-error:hover .dz-error-message {
            display: none;
          }
        }
        .upload-cut-btns-container {
          width: 80%;
          margin-top: 2%;
          @media screen and (max-width: 1024px) {
            margin-top: 5%;
            width: 100%;
          }
        }
      }
      .modify-qr-table-container {
        height: 100%;
        .modify-qr-table {
          border-radius: 0px;
          border: 1px solid black;
          .modify-qr-barber-table {
            .barber-data-table-header {
              background-color: rgb(255, 227, 143);
              .barber-username {
                font-family: system-ui, -apple-system, BlinkMacSystemFont,
                  "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                  "Helvetica Neue", sans-serif;
                font-size: 1rem;
              }
            }
            .barber-data-table-body {
              tr:nth-child(odd) {
                background-color: rgba(216, 216, 216, 0.65);
              }
              .barber-data-cell-title {
                font-family: system-ui, -apple-system, BlinkMacSystemFont,
                  "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                  "Helvetica Neue", sans-serif;
                font-weight: bold;
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
