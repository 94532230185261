.ls-main-screen {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* padding: 1%; */
  .ls-screen-title {
    padding: 1%;
    text-align: center;
    .ls-title {
      font-size: 1.7em;
      letter-spacing: 0.12rem;
      margin-bottom: 1%;
      /* background: -webkit-linear-gradient(bottom, #b6b86d, #1f2023);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; */
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    }
  }
  .ls-login-buttons-container {
    display: "flex";
    flex-wrap: "wrap";
    min-width: 300;
    width: 100%;
  }
  .ls-register-btn-container {
    border: 2px solid black;
    width: 100%;
    margin-top: 3%;
  }
  .create-account-button-container {
    margin-top: 3%;
    .create-account-button {
      padding: 0.5%;
    }
  }
}
