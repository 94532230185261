.rates-detail-page {
  width: 100%;
  .rates-detail-title-container {
    display: flex;
    justify-content: center;
    .rates-detail-title {
      width: 75%;
      display: flex;
      align-self: center;
      justify-content: center;
      text-align: center;
      > div {
        /* background-color: rgb(45, 45, 45); */
        background-color: rgba(31, 32, 45, 0.885);
        border-radius: 15px;
        color: #ffc107;
        padding: 3%;
        font-size: 2.55rem;
        letter-spacing: 0.0000833em;
        font-weight: 400;
        /* text-shadow: -3px 2px 1px rgba(255, 255, 255, 0.261); */
      }
    }
  }
  .rates-detail-main-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .rates-detail-table-container {
      height: 100%;
      width: 75%;
      @media screen and (max-width: 1024px) {
        width: 100%;
        margin-bottom: 5%;
      }
      .rates-detail-table {
        width: 100%;
        .rates-detail-table-header {
          background-color: rgb(255, 227, 143);
          .rates-detail-table-title {
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
              "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
              "Helvetica Neue", sans-serif;
            font-size: 1.25rem;
          }
        }
        .rates-detail-table-body {
          tr:nth-child(odd) {
            background-color: rgba(216, 216, 216, 0.65);
          }
          .rates-detail-cell-title {
            width: 50%;
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
              "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
              "Helvetica Neue", sans-serif;
            font-weight: bold;
            font-size: 1rem;
          }
          .rates-detail-cell {
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
              "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
              "Helvetica Neue", sans-serif;
            font-size: 1.05rem;
          }
        }
      }
    }
  }
  .rates-detail-back-button-container {
    width: 100%;
    margin-top: 2%;
    display: flex;
    justify-content: center;
  }
}
