.sales-home-screen {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .sales-home-title-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .sales-home-title {
      font-size: 2.05em;
      letter-spacing: 0.03em;
      text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
    }
  }
  .sales-home-main-container {
    padding: 1%;
    .sales-home-grid {
      padding: 0;
      margin: 0;
      width: 100%;
      .sales-home-grid-item {
        padding: 1%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        @media screen and (max-width: 1024px) {
          margin-top: 2%;
          padding: 5% 0;
        }
        .grid-item-title {
          font-size: 0.95em;
        }
        .grid-item-chart-container {
          width: 100%;
          box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
            0px 5px 8px 0px rgba(0, 0, 0, 0.14),
            0px 1px 14px 0px rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
  .separator {
    margin: 2% 0;
  }
  .sales-home-months-container {
    padding: 1%;
    .sales-home-months-grid {
      padding: 0;
      margin: 0;
      width: 100%;
      .months-grid-item {
        padding: 1%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media screen and (max-width: 1024px) {
          margin-top: 2%;
          padding: 5% 0;
        }
        .grid-item-title {
          font-size: 0.95em;
        }
      }
    }
  }
}
