.customSelect-main-container {
  /* padding: 3%; */
  .inner-container {
    .customSelect-label {
      color: black;
    }
    .error-label,
    .error-style {
      color: red;
    }
  }
}
