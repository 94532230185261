.loading-screen {
  .dialog-body {
    .img-container {
      width: 100%;
      height: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .loader-container {
      margin-top: 6%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.confirm-text-container {
  .title-container {
    text-align: center;
    padding: 2%;
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
}
