.login-screen {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .login-form-container {
    display: flex;
    justify-content: center;
  }
}
