.bar-chart-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .bar-chart-inner-container {
    width: 100%;
    height: 350px;
    max-height: 350px;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
