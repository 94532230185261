.no-data-card-main-container {
  display: flex;
  justify-content: center;
  margin: 5% 0%;
  .no-data-card {
    padding: 3%;
    background-color: rgb(255, 193, 7, 0.63);
    border-radius: 15px;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .no-data-card-content {
      text-align: center;
    }
    .no-data-card-buttons-container {
      .no-data-card-buttons {
        margin-top: 2%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

/* .no-data {
  margin: 2%;
  align-self: center;
  background-color: rgb(255, 193, 7, 0.63);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 25px;
  width: 50%;
  text-align: center;
  padding: 5%;
  font-size: 2em;
  .buttons-container {
    margin-top: 7%;
    display: flex;
    justify-content: space-around;
  }
}
 */
