.barbers-list-main-container {
  max-height: 100%;
  .barbers-list-search-bar {
    max-height: 7vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .barbers-search-bar-input {
      margin: 0px;
      padding: 0px;
      width: 95%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }
  .barbers-list {
    width: 100%;
    max-height: 64vh;
    overflow: auto;
    padding: 0px 12px;
    @media screen and (max-width: 768px) {
      padding: 1% 4%;
    }
    @media screen and (max-width: 1024px) {
      padding: 1% 5%;
    }
    .barbers-list-item {
      margin: 2% 0;
      border: 1px solid black;
      padding: 2%;
      border-radius: 8px;
      background-color: rgb(255, 192, 7, 0.45);
      cursor: pointer;
      &:hover {
        transform: scale(1.005);
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
          rgba(0, 0, 0, 0.23) 0px 6px 6px;
      }
      &:active {
        transform: scale(1);
        box-shadow: none;
      }
      @media screen and (max-width: 768px) {
        margin: 3% 0;
      }
      .list-item-data {
        > .MuiListItemText-primary {
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        }
      }
    }
  }
}
