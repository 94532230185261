.bills-detail-screen-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .bills-detail-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .bills-detail-title {
      width: 40%;
      text-align: center;
      background-color: rgba(31, 32, 45, 0.885);
      border-radius: 15px;
      color: #ffc107;
      padding: 1%;
      font-size: 2.15rem;
      letter-spacing: 0.0000833em;
      font-weight: 400;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }
  .bills-detail-main-container {
    margin-top: 1%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .bills-detail-main-grid {
      margin: 0;
      padding: 0;
      width: 100%;
      .bills-detail-grid-item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1%;
        .grid-item-data-container {
          width: 100%;
          background-color: rgb(240, 228, 130);
          display: flex;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }
}
