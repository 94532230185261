.loading-main-container {
  .loading-screen-body {
    .loading-img-container {
      width: 100%;
      height: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .loader-container {
      margin-top: 6%;
      margin-bottom: 2%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.confirm-screen {
  .title-container {
    text-align: center;
    padding: 2%;
  }
  .confirm-button-container {
    display: flex;
    justify-content: center;
  }
}
