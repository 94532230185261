.homeDashboard-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
  @media screen and (max-width: 1024px) {
    height: 100vh;
  }
  .home-dashboard-stack {
    width: 100%;
    height: 100%;
    @media screen and (max-width: 1024px) {
      padding: 10px;
    }
    .home-dashboard-stack-welcome {
      padding: 8px;
      width: 50%;
      @media screen and (max-width: 1024px) {
        height: 45%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .home-image-container {
        border-radius: 10px;
        height: 100%;
        display: flex;
        justify-content: center;
        .home-logo-image {
          max-width: 100%;
          object-fit: cover;
          @media screen and (max-width: 1024px) {
            max-width: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .home-dashboard-stack-notifications {
      padding: 8px;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 1024px) {
        width: 100%;
        margin-top: 2%;
        height: 100%;
      }
      .notifications-content {
        border: 1px solid lightgray;
        padding: 1%;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        box-shadow: 8px 5px 5px #757575;
        .notifications-grid {
          width: 100%;
          margin: 0;
          padding: 0;
          .notifications-grid-row-title {
            padding: 2% 1%;
            display: flex;
            justify-content: center;
            .notifications-title {
              @media screen and (max-width: 1024px) {
                width: 75%;
              }
              width: 43%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 1.7rem;
              letter-spacing: 0.03em;
              text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
            }
          }
          .notifications-column {
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            .notifications-list {
              scrollbar-width: "10px";
              width: 90%;
              height: 60vh;
              max-height: 60vh;
              overflow: auto;
              @media screen and (max-width: 1024px) {
                height: 62vh;
                max-height: 62vh;
              }
              .notification-circle {
                padding: 0px;
                width: 95%;
                margin: 2% 0;
                .notification-body {
                  border: 1px solid black;
                  border-radius: 8px;
                  background-color: rgba(255, 193, 5, 0.225);
                }
              }
            }
          }
        }
      }
    }
  }
}
