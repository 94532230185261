.performance-card {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: #f4f4f4;
  padding: 1%;
  width: 80%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  .performance-card-separator {
    margin: 2% 0 1%;
  }
  .performance-table-container {
    min-height: 35vh;
    display: flex;
    justify-content: center;
    .performance-table {
      width: 100%;
      tr:nth-child(odd) {
        background-color: rgba(216, 216, 216, 0.65);
      }
      .performance-table-title {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
          Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
          sans-serif;
        padding: 2% 5px;
        text-align: start;
        width: 50%;
        @media screen and (max-width: 1024px) {
          padding: 3%;
        }
      }
      .performance-table-data {
        padding: 2%;
        @media screen and (max-width: 1024px) {
          padding: 3%;
          text-align: center;
        }
      }
    }
  }
}
