.custom-form-main-container {
  border: 1px solid lightgray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  @media screen and (max-width: 1024px) {
    width: 95%;
    margin-top: 2%;
  }
  .custom-form-title-container {
    width: 100%;
    text-align: center;
    padding: 2% 0;
    .custom-form-title {
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
      font-size: 1.35rem;
      text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
    }
  }
  .custom-form-separator {
    width: 90%;
    margin: 5px 0;
  }
  .custom-form-container {
    width: 100%;
    height: 100%;
    .custom-form {
      .custom-form-grid {
        justify-content: center;
        .custom-form-grid-item {
          width: 80%;
          margin: 3% 0;
          padding: 0 5%;
          @media screen and (max-width: 1024px) {
            margin: 4% 0;
            width: 95%;
          }
          .custom-form-qr-display {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .custom-form-qr-title {
              text-align: center;
            }
            .custom-form-qr-img {
              width: 50%;
              @media screen and (max-width: 1024px) {
                width: 70%;
              }
            }
          }
        }
        .custom-form-grid-item-xs {
          width: 80%;
          margin: 3% 2%;
          padding: 0 1%;
          @media screen and (max-width: 1024px) {
            margin: 4% 0;
            width: 95%;
            padding: 0 5%;
          }
        }
        .custom-form-buttons-container {
          margin-top: 1%;
          width: 100%;
          .custom-form-buttons-stack {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .custom-form-back-button-container {
            margin: 2% 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @media screen and (max-width: 1024px) {
              margin-top: 5%;
            }
          }
        }
      }
    }
  }
}
