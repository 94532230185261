.sellersHome-main-screen {
  width: 100%;
  .sellersHome-screen-title {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
    font-size: 2.05em;
    letter-spacing: 0.03em;
    text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
  }
}
