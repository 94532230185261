.barbers-summary-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .barbers-summary-dates-container {
    border: 1px solid #ffc107;
    width: 70%;
    @media screen and (max-width: 768px) {
      width: 90%;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgb(239, 239, 239, 0.7);
    border-radius: 10px;
    padding: 2% 0.5%;
    margin-bottom: 2%;
    @media screen and (max-width: 768px) {
      margin-top: 3%;
      padding: 0 0 8% 0;
    }
  }
  .barbers-summary-grid-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .barbers-summary-grid {
      margin: 0px;
      padding: 0px;
      .barbers-summary-grid-item {
        padding: 1%;
        @media screen and (max-width: 1024px) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .barbers-summary-data-container {
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 8px;
          background-color: #f4f4f4;
          padding: 1%;
          @media screen and (max-width: 1024px) {
            margin: 2% 0;
            width: 95%;
          }
          .barbers-summary-card-title {
            display: flex;
            justify-content: center;
            text-align: center;
            padding: 1%;
            > h6 {
              font-size: 1.35rem;
            }
          }
          .barbers-summary-card-body {
            border-top: 1px solid #ffc107;
            border-bottom: 1px solid #ffc107;
            display: flex;
            padding: 3% 7%;
            .barbers-summary-data {
              margin: 5% 0;
            }
          }
          .barbers-summary-card-btn-container {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
