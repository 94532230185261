.bills-form-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    padding: 6% 0;
  }
  .bills-select-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 0px;
    .select-grid-container {
      width: 50%;
      margin: 0px;
      padding: 0px;
      @media screen and (max-width: 768px) {
        width: 80%;
      }
      .grid-select-item > * {
        padding: 0px;
        margin: 0px;
      }
    }
  }
  .date-range-title {
    padding: 3% 1%;
    @media screen and (max-width: 768px) {
      padding: 5%;
    }
  }
  .bills-grid-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .bills-form-grid {
      width: 55%;
      @media screen and (max-width: 768px) {
        width: 80%;
      }
      padding: 0px;
      margin: 0px;
      .bills-form-grid-item {
        padding: 0px;
      }
      .grid-button-container {
        padding: 0px;
        .grid-button {
          width: 100%;
          margin-top: 2%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
