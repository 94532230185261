.register-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  .register-forms-container {
    display: flex;
    justify-content: center;
    height: 100%;
    margin: 0 0 2% 0;
  }
}
