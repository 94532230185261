.delete-loading-main-container {
  .loading-delete-screen {
    .delete-img-container {
      width: 100%;
      height: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .delete-loader-container {
      margin-top: 6%;
      margin-bottom: 2%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.confirm-delete-screen {
  .confirm-title-container {
    text-align: center;
    padding: 2%;
  }
  .delete-button-container {
    display: flex;
    justify-content: center;
  }
}
