/* .metrics-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .metrics-screen-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1%;
    .metrics-screen-title {
      text-align: center;
      padding-bottom: 1%;
      font-size: 2.05em;
      letter-spacing: 0.03em;
      text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
    }
  }
  .metrics-tabs-container {
    border-top: 1px solid #ffc107;
    margin-top: 2%;
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 1024px) {
      max-width: 100vw;
    }
    .metrics-branch-office-name-container {
      width: 70%;
      margin-bottom: 2%;
      .metrics-branch-office-name {
        text-align: center;
        padding: 1% 0%;
        font-size: 1.55em;
        letter-spacing: 0.03em;
        text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
      }
    }
    .metrics-tabs-inner-container {
      width: 100%;
      .metrics-tabs-buttons {
        border-bottom: 1px solid lightgray;
        display: flex;
        width: 100%;
        margin-bottom: 2%;
        .metrics-tabs-button-nav {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
        .metrics-tabs {
          width: 25%;
          @media screen and (max-width: 768px) {
            margin: 0% 3%;
          }
        }
      }
    }
  }
}
 */

.metrics-screen {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .metrics-main-container {
    border: 1px solid lightgray;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    min-height: 15vh;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    @media screen and (max-width: 1024px) {
      min-height: 25vh;
      width: 95%;
      margin-top: 2%;
    }
    .metrics-header-container {
      width: 100%;
      text-align: center;
      padding: 2%;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1024px) {
        padding: 1% 0;
        flex-direction: column;
      }
      .metrics-title {
        width: 20%;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
          Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
          sans-serif;
        text-align: flex-start;
        font-size: 2.05em;
        letter-spacing: 0.03em;
        text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
        font-weight: 400;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }
      .metrics-select-container {
        width: 80%;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1024px) {
          width: 100%;
          margin-top: 5%;
        }
      }
    }
    .metrics-inner-container {
      width: 100%;
      height: 100%;
      margin-top: 1%;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1024px) {
        margin-top: 5%;
      }
      .selected-title-container {
        width: 100%;
        text-align: center;
        padding: 2% 0 0 0;
        .selected-title {
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
          font-size: 1.35rem;
          text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
        }
      }
      .metrics-tabs-container {
        width: 100%;
        margin-top: 1%;
        @media screen and (max-width: 1024px) {
          margin-top: 5%;
        }
        .metrics-tabs-buttons {
          border-bottom: 1px solid lightgray;
          display: flex;
          width: 100%;
          margin-bottom: 2%;
          .metrics-tabs-buttons-nav {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 100vw;
            overflow: hidden;
            .metrics-tab {
              width: calc(100% / 4);
              @media screen and (max-width: 1024px) {
                width: calc(100% / 2.5);
                padding: 3%;
              }
            }
          }
        }
      }
      .metrics-tabpanel {
        height: 100%;
        padding: 5px;
      }
    }
  }
}
