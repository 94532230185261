.monthly-summary-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .monthly-summary-grid {
    width: 100%;
    justify-content: center;
    .monthly-summary-grid-item {
      padding: 1.5%;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        padding: 3%;
      }
      .year-selector-container {
        width: 50%;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }
      .monthly-summary-data-container {
        border: 1px solid lightgray;
        border-radius: 8px;
        width: 100%;
        min-height: 22vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        .monthly-summary-data-title {
          font-size: 1.035rem;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        }
        .monthly-summary-data {
          font-size: 1.35rem;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        }
      }
    }
  }
}
