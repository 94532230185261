.sales-data-card {
  border-radius: 8px;
  padding: 1%;
  width: 85%;
  flex-grow: 1;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  .sales-data-card-header {
    .css-1ssile9-MuiCardHeader-avatar {
      display: flex;
      justify-content: center;
      .css-2s90m6-MuiAvatar-root {
        border: 2px solid #ffc107;
      }
    }
    .css-et1ao3-MuiTypography-root {
      text-align: start;
      font-size: 1rem;
    }
    .css-83ijpv-MuiTypography-root {
      text-align: start;
      font-size: 0.95rem;
    }
  }
  .sales-data-card-separator {
    margin: 1% 0;
  }
  .sales-data-card-body {
    .sales-data-card-grid {
      margin: 0;
      padding: 0;
      width: 100%;
      .sales-data-card-grid-item {
        margin: 0;
        padding: 1%;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1024px) {
          margin-top: 1%;
        }
        .grid-item-data-title {
          width: 60%;
          text-align: start;
          @media screen and (max-width: 1024px) {
            width: 70%;
          }
        }
        .grid-item-value {
          width: 40%;
          text-align: center;
          @media screen and (max-width: 1024px) {
            width: 30%;
          }
        }
      }
    }
  }
}
