.rates-home-screen {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .rates-home-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 100%;
    @media screen and (max-width: 1024px) {
      width: 95%;
    }
    .rates-home-screen-header {
      width: 100%;
      padding: 2% 0;
      display: flex;
      justify-content: space-between;
      .rates-title-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .rates-title {
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
          text-align: flex-start;
          font-size: 2.05em;
          letter-spacing: 0.03em;
          text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
          font-weight: 400;
        }
      }
      .rates-add-btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .day-accordion-container {
      margin-top: 1.5%;
      margin-bottom: 1.5%;
      width: 100%;
      .parent-accordion-panel {
        background-color: rgba(255, 211, 78, 0.775);
        border-radius: 5px;
        .parent-accordion-titles {
          font-size: 1.15em;
          font-weight: 400;
          letter-spacing: 0.115em;
          .parent-accordion-attribute-container {
            align-self: center;
            width: 80%;
            margin-left: 2%;
          }
        }
      }
      .schedule-accordion-container {
        margin: 1% auto;
        width: 90%;
        .child-accordion-panel {
          background-color: rgb(255, 231, 158);
          border-radius: 5px;
          .child-accordion-titles {
            &:focus {
              font-weight: bold;
              font-size: 1.05em;
            }
            font-size: 1.02em;
            font-weight: 400;
            letter-spacing: 0.05em;
          }
        }
      }
    }
  }
}
